const Catch = fn =>
  function(...args) {
    try {
      fn.apply(this, args);
      console.log("pixel success", fn.name, args);
    } catch (e) {
      console.error("pixel ex:", e);
    }
    return;
  };

const Pixel = {
  addToCart: Catch((contents, value, event_id) => {
    window.fbq(
      "track",
      "AddToCart",
      {
        contents,
        content_type: "product",
        value,
        currency: "TWD",
      },
      { eventID: event_id }
    );
  }),
  viewContent: Catch(content_ids => {
    window.fbq("track", "ViewContent", {
      content_ids,
      content_type: "product",
    });
  }),
  initCheckout: Catch(eventID => {
    window.fbq("track", "InitiateCheckout", {}, { eventID });
  }),
  purchase: Catch((value, _contents, eventID) => {
    let contents = _contents.map(content => {
      try {
        let q1 = parseInt(content.config.quantity_select),
          q2 = parseInt(content.quantity);

        return {
          id: content.name,
          quantity: q1 > q2 ? q1 : q2,
        };
      } catch (e) {
        return {
          id: content.name,
          quantity: 1,
        };
      }
    });
    console.log("fb pixel purchase", contents);
    window.fbq(
      "track",
      "Purchase",
      {
        content_type: "product",
        value,
        contents,
        currency: "TWD",
      },
      { eventID }
    );
  }),
  search: Catch(search_string => {
    window.fbq("track", "Search", {
      search_string,
    });
  }),
};

export default Pixel;
