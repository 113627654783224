import React, { useState, Fragment, useEffect } from "react";
import styled from "styled-components";
import * as Theme from "../Theme";
import { Link } from "gatsby";
import * as Icon from "./Icon";

export default function CollapseRow({
  item,
  children,
  extraStyle = {},
  onClose = () => 0,
}) {
  const [open, setOpen] = useState(false);

  if (children) {
    return (
      <Fragment>
        <Item onClick={() => setOpen(!open)}>
          <div style={{ flex: 1, color: "#5a5a5a", textAlign: "center" }}>
            {item.label}
            {open && (
              <Icon.KeyboardArrowUp
                color="#5a5a5a"
                size={16}
                css="flex-grow: 0;margin-left:10px;"
              />
            )}
          </div>
        </Item>
        {open ? <div style={{ textAlign: "center" }}>{children}</div> : null}
      </Fragment>
    );
  } else if (item.url) {
    if (item.withHost) {
      return (
        <Item>
          <a href={item.url} target="_blank" onClick={onClose}>
            <div
              style={{
                flex: 1,
                color: "#5a5a5a",
                justifyContent: "center",
                alignItems: "center",
                ...extraStyle,
              }}
            >
              <div style={{ display: "flex" }}>
                <div className="label">{item.label}</div>
                <div style={{ display: "flex", width: 10 }}>&nbsp;</div>
                <div className="sub-label">{item.subLabel}</div>
              </div>
            </div>
          </a>
        </Item>
      );
    }
    return (
      <Item>
        <Link to={item.url}>
          <div
            style={{
              flex: 1,
              color: "#5a5a5a",
              justifyContent: "center",
              alignItems: "center",
              ...extraStyle,
            }}
            onClick={onClose}
          >
            <div style={{ display: "flex" }}>
              <div className="label">{item.label}</div>
              <div style={{ display: "flex", width: 10 }}>&nbsp;</div>
              <div className="sub-label">{item.subLabel}</div>
            </div>
          </div>
        </Link>
      </Item>
    );
  } else {
    return (
      <Item>
        <div style={{ flex: 1, color: "#5a5a5a" }}>{item.label}</div>
      </Item>
    );
  }
}

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 16px;
  color: #5a5a5a;
  cursor: pointer;
  margin-bottom: 10px;

  & .label {
    display: flex;
    flex: 2;
    justify-content: flex-end;
    font-weight: 350;
    letter-spacing: 1.05px;
  }
  & .sub-label {
    display: flex;
    flex: 2;
    font-size: 14px;
    color: #9b9b9b;
    font-weight: 300;
  }

  &:hover {
    opacity: 0.5;
  }

  ${props => props.css || ""}
`;
