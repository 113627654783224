import React, { useContext, useEffect, useRef } from "react";
import { connect, Provider as ReduxProvider } from "react-redux";
import { getStore } from "./Store";
import Layout from "./Layout";
import Selectors from "./Selectors";
import ActionCreator from "./ActionCreator";
import * as Cart from "./Contexts/CartContext";
import ErrorBoundary from "./ErrorBoundary";
import "antd/dist/antd.less";
import ActivityIndicator from "./Components/ActivityIndicator";
import * as AppContext from "./AppContext";
import constants from "../src/constants";

if (!constants.debug || typeof window === "undefined") {
  console.log = () => 0;
  console.group = () => 0;
  console.groupEnd = () => 0;
}

export function PageContainer(props) {
  const { pageContext, children, ...extraProps } = props;
  return (
    <WithPageContext>
      <ErrorBoundary>
        <Layout {...extraProps} seo={pageContext.seo}>
          {props.children}
        </Layout>
      </ErrorBoundary>
    </WithPageContext>
  );
}

function WithPageContext({ appActions, children, profile }) {
  const app = useContext(AppContext.Context);
  useEffect(() => {
    (async () => {
      if (!profile) {
        await app.actions.autoLogin();
        // TODO: deprecated
        await appActions.autoLogin({ setToken: app.actions.setToken });
        Cart.Actions.fetchCart();
      }
    })();
  }, [appActions, profile]);

  return children;
}

WithPageContext = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(WithPageContext);

export function AppRoot(props) {
  // const store = getStore(props.pageContext.initialState)
  const store = useRef(getStore({}));

  return (
    <ReduxProvider store={store.current}>
      <AppContext.Provider {...props}>
        <Cart.Provider>
          {props.children}
          <ActivityIndicator />
        </Cart.Provider>
      </AppContext.Provider>
    </ReduxProvider>
  );
}
