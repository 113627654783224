import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [iconColor, setIconColor] = useState("#fff");

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const throttle = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (!timeoutId) {
        func(...args);
        timeoutId = setTimeout(() => {
          timeoutId = null;
        }, delay);
      }
    };
  };

  useEffect(() => {
    const handleScroll = throttle(toggleVisibility, 200);

    window.addEventListener("scroll", handleScroll);

    toggleVisibility();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleMouseEnter = () => {
    setIconColor("#FF5520");
  };

  const handleMouseLeave = () => {
    setIconColor("#fff");
  };

  return (
    <>
      {isVisible && (
        <StyledButton
          onClick={scrollToTop}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_406_3362"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="32"
              height="32"
            >
              <rect width="32" height="32" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_406_3362)">
              <path
                d="M13.2075 27.5556L14.9783 11.2801L13.1848 13.3494C10.8464 15.7675 7.69079 19.0226 4.44434 19.3946V15.0932C9.98374 14.6747 14.4334 10.1176 14.8421 4.44446H17.1577C17.5664 10.1176 22.016 14.6747 27.5554 15.0932V19.3946C24.309 19.0226 21.1761 15.7675 18.8377 13.3727L17.0442 11.3034L18.5426 27.5556H13.2075Z"
                fill={iconColor}
              />
            </g>
          </svg>
        </StyledButton>
      )}
    </>
  );
};

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  bottom: 14px;
  right: 23px;
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  &:hover {
    background-color: #434343;
  }
`;

export default ScrollToTopButton;
