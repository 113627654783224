import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import * as Theme from "../Theme";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import CollapseRow from "./CollapseRow";
import { SocialLinks } from "./Footer";
import Mask from "./Mask";

function Menu({ items, show, onClose = () => 0 }) {
  const [open, setOpen] = useState(show);

  useEffect(() => {
    if (show) {
      setOpen(show);
    } else {
      let timer = setTimeout(() => {
        setOpen(show);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <Fragment>
      <Mask visible={show} onClick={onClose} />

      <BkgImgWrapper visible={show} className={show ? "show" : ""}>
        {open && (
          <Fragment>
            <img
              className={"banner-img "}
              alt="navbar bkg"
              src="../../images/navbar-bkg.jpg"
            />
            <MenuWrapper>
              <img
                src="/images/logo-vertical.png"
                alt="logo"
                width={270 * 0.4 + ""}
                height={263 * 0.4 + ""}
                style={{ marginBottom: 50, marginTop: 60 }}
              />
              {items.map((item, idx) => {
                return (
                  <CollapseRow
                    onClose={onClose}
                    key={idx}
                    item={item}
                    extraStyle={{ width: 150 }}
                  />
                );
              })}

              <SocialLinks style={{ alignSelf: "center", marginTop: 40 }} />
            </MenuWrapper>
          </Fragment>
        )}
      </BkgImgWrapper>
    </Fragment>
  );
}

const BkgImgWrapper = styled.div`
  z-index: 16;
  position: fixed;
  top: ${Theme.navbarHeight}px;
  width: 100%;
  height: 100%;
  /* opacity: ${props => (props.visible ? 1 : 0)}; */
  pointer-events: ${props => (props.visible ? "auto" : "none")};
  transition: all 0.5s ease-in;
  opacity: 0;
  /* transform: translateX(100%); */

  &.show {
    /* transform: none; */
    opacity: 1;
  }

  & > .banner-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* transform: translateX(50%);
    transition: all 0.4s ease-out 0.2s; */
  }

  /* & > .banner-img.show {
    transform: none;
  } */

  @media only screen and (max-width: ${Theme.breakpoints.lg}px) {
    background-image: none;
  }
`;

const MenuWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Theme.colors.eggShell};
  right: 0px;
  height: 100%;
  width: 30%;
  top: 0px;

  @media only screen and (max-width: ${Theme.breakpoints.lg}px) {
    width: 100%;
    height: calc(100vh - ${Theme.navbarHeightMore - 3}px);
    top: -11px; /* hot fix */
  }
`;

export default connect(null, ActionCreator)(Menu);
