// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-templates-about-page-js": () => import("./../../../src/PageTemplates/AboutPage.js" /* webpackChunkName: "component---src-page-templates-about-page-js" */),
  "component---src-page-templates-account-manage-page-js": () => import("./../../../src/PageTemplates/AccountManagePage.js" /* webpackChunkName: "component---src-page-templates-account-manage-page-js" */),
  "component---src-page-templates-article-detail-page-js": () => import("./../../../src/PageTemplates/Article/DetailPage.js" /* webpackChunkName: "component---src-page-templates-article-detail-page-js" */),
  "component---src-page-templates-article-list-page-js": () => import("./../../../src/PageTemplates/Article/ListPage.js" /* webpackChunkName: "component---src-page-templates-article-list-page-js" */),
  "component---src-page-templates-auth-result-page-js": () => import("./../../../src/PageTemplates/AuthResultPage.js" /* webpackChunkName: "component---src-page-templates-auth-result-page-js" */),
  "component---src-page-templates-bonus-page-js": () => import("./../../../src/PageTemplates/BonusPage.js" /* webpackChunkName: "component---src-page-templates-bonus-page-js" */),
  "component---src-page-templates-faq-index-js": () => import("./../../../src/PageTemplates/Faq/index.js" /* webpackChunkName: "component---src-page-templates-faq-index-js" */),
  "component---src-page-templates-forget-password-page-js": () => import("./../../../src/PageTemplates/ForgetPasswordPage.js" /* webpackChunkName: "component---src-page-templates-forget-password-page-js" */),
  "component---src-page-templates-landing-page-js": () => import("./../../../src/PageTemplates/LandingPage.js" /* webpackChunkName: "component---src-page-templates-landing-page-js" */),
  "component---src-page-templates-monthly-manage-page-js": () => import("./../../../src/PageTemplates/MonthlyManagePage.js" /* webpackChunkName: "component---src-page-templates-monthly-manage-page-js" */),
  "component---src-page-templates-new-cart-page-js": () => import("./../../../src/PageTemplates/NewCartPage.js" /* webpackChunkName: "component---src-page-templates-new-cart-page-js" */),
  "component---src-page-templates-order-detail-page-js": () => import("./../../../src/PageTemplates/OrderDetailPage.js" /* webpackChunkName: "component---src-page-templates-order-detail-page-js" */),
  "component---src-page-templates-order-list-page-js": () => import("./../../../src/PageTemplates/OrderListPage.js" /* webpackChunkName: "component---src-page-templates-order-list-page-js" */),
  "component---src-page-templates-period-order-list-page-js": () => import("./../../../src/PageTemplates/PeriodOrderListPage.js" /* webpackChunkName: "component---src-page-templates-period-order-list-page-js" */),
  "component---src-page-templates-period-page-js": () => import("./../../../src/PageTemplates/PeriodPage.js" /* webpackChunkName: "component---src-page-templates-period-page-js" */),
  "component---src-page-templates-policy-page-js": () => import("./../../../src/PageTemplates/PolicyPage.js" /* webpackChunkName: "component---src-page-templates-policy-page-js" */),
  "component---src-page-templates-product-detail-page-js": () => import("./../../../src/PageTemplates/ProductDetailPage.js" /* webpackChunkName: "component---src-page-templates-product-detail-page-js" */),
  "component---src-page-templates-product-list-index-js": () => import("./../../../src/PageTemplates/ProductList/index.js" /* webpackChunkName: "component---src-page-templates-product-list-index-js" */),
  "component---src-page-templates-profile-page-js": () => import("./../../../src/PageTemplates/ProfilePage.js" /* webpackChunkName: "component---src-page-templates-profile-page-js" */),
  "component---src-page-templates-quote-page-index-js": () => import("./../../../src/PageTemplates/QuotePage/index.js" /* webpackChunkName: "component---src-page-templates-quote-page-index-js" */),
  "component---src-page-templates-register-page-js": () => import("./../../../src/PageTemplates/RegisterPage.js" /* webpackChunkName: "component---src-page-templates-register-page-js" */),
  "component---src-page-templates-terms-page-js": () => import("./../../../src/PageTemplates/TermsPage.js" /* webpackChunkName: "component---src-page-templates-terms-page-js" */),
  "component---src-page-templates-validation-page-js": () => import("./../../../src/PageTemplates/ValidationPage.js" /* webpackChunkName: "component---src-page-templates-validation-page-js" */),
  "component---src-page-templates-work-list-page-js": () => import("./../../../src/PageTemplates/WorkListPage.js" /* webpackChunkName: "component---src-page-templates-work-list-page-js" */),
  "component---src-page-templates-work-page-index-js": () => import("./../../../src/PageTemplates/WorkPage/index.js" /* webpackChunkName: "component---src-page-templates-work-page-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */)
}

