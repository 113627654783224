import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, Input, Button, Alert } from "antd";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import { Link } from "gatsby";
import * as Constants from "../constants";
import * as Cart from "../Contexts/CartContext";
import Selectors from "../Selectors";
import { Context } from "../AppContext";
import { ErrLogin, errorHandler } from "../errors";

const FacebookLogo = "/images/facebook-signin.png";
const GoogleLogo = "/images/google-signin.png";

const defaultValues = {
  username: "",
  password: "",
  gui_number: "",
};

const UISTATE = {
  normal: "normal",
  enterprise: "enterprise",
};

function LoginModal({ appActions }) {
  const [uiState, setUiState] = useState(UISTATE.normal);
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const app = useContext(Context);
  const open = app.state.openLoginModal;

  useEffect(() => {
    if (!open) {
      setValues(defaultValues);
      setError(null);
    }
  }, [open]);

  const login = async () => {
    const { username, password, gui_number } = values;

    setLoading(true);
    try {
      if (uiState === UISTATE.enterprise && !gui_number) {
        throw new ErrLogin("統編必填");
      }

      if (!username) {
        throw new ErrLogin("信箱必填");
      }
      if (!password) {
        throw new ErrLogin("密碼必填");
      }

      await app.actions.login({
        username,
        password,
        gui_number,
      });
      // TODO: deprecated
      await appActions.login({
        username,
        password,
        gui_number,
        setToken: app.actions.setToken,
      });
      Cart.Actions.fetchCart();

      app.actions.setLoginModal(false);
    } catch (ex) {
      if (ex.error === "user_type_error") {
        ex = new ErrLogin("此帳號非此會員類型，請選擇正確的類型");
      } else {
        setError(true);
      }

      errorHandler(ex, "登入失敗");
    }
    setLoading(false);
  };

  return (
    <Modal
      visible={open}
      onCancel={() => app.actions.setLoginModal(false)}
      footer={
        <div style={{ display: "flex", justifyContent: "center" }}>
          還不是會員嗎？
          <Link
            to="/register"
            onClick={() => {
              app.actions.setEnterpriseRegister(
                uiState === UISTATE.enterprise ? true : false
              );
            }}
          >
            立刻註冊新帳號
          </Link>
        </div>
      }
      width="400px"
    >
      <div style={{ margin: "0 auto 20px", textAlign: "center" }}>
        <img src="/images/logo.png" width="150" />
      </div>
      {error && (
        <Alert
          message="登入失敗"
          description="請重新確認您的信箱與密碼，然後再試一次"
          type="error"
          showIcon
          style={{ marginBottom: 10 }}
        />
      )}

      <div style={{ display: "flex", marginBottom: 10 }}>
        <Button
          type={uiState === UISTATE.normal ? "primary" : "default"}
          onClick={() => {
            setValues(prev => ({ ...prev, gui_number: "" }));
            setUiState(UISTATE.normal);
          }}
          style={{ flex: 1 }}
        >
          一般會員
        </Button>
        <Button
          type={uiState === UISTATE.enterprise ? "primary" : "default"}
          onClick={() => setUiState(UISTATE.enterprise)}
          style={{ flex: 1 }}
        >
          企業會員
        </Button>
      </div>

      {uiState === UISTATE.normal ? (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              alignItems: "stretch",
              flexDirection: "column",
            }}
          >
            <Input
              id="login-username-input"
              placeholder="信箱"
              value={values.username}
              style={{ padding: 12, marginBottom: 10 }}
              onChange={e => {
                const value = e.target.value;
                setValues(prev => ({ ...prev, username: value }));
                setError(null);
              }}
            />

            {/* <label htmlFor="login-password-input">密碼</label> */}
            <Input
              id="login-password-input"
              placeholder="密碼"
              type="password"
              value={values.password}
              style={{ padding: 12 }}
              onChange={e => {
                const value = e.target.value;
                setValues(prev => ({ ...prev, password: value }));
                setError(null);
              }}
            />
          </div>

          <BottomBar>
            <Button
              type="primary"
              loading={loading}
              onClick={login}
              style={{ flex: 1, height: 40 }}
            >
              登入
            </Button>
          </BottomBar>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="link" style={{ display: "block" }}>
              <Link to="/fgpass" style={{ fontSize: 12, color: "#9b9b9b" }}>
                忘記密碼？
              </Link>
            </Button>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{ flex: 1, height: 1, backgroundColor: "darkgray" }}
            ></div>
            <div style={{ margin: 10 }}>或</div>
            <div
              style={{ flex: 1, height: 1, backgroundColor: "darkgray" }}
            ></div>
          </div>

          <SocialSignInGroup>
            <SocialSignInBtn>
              <a href={`${Constants.apiUrl}/auth/facebook`}>
                <img src={FacebookLogo} />
              </a>
            </SocialSignInBtn>

            <SocialSignInBtn>
              <a href={`${Constants.apiUrl}/auth/google`}>
                <img src={GoogleLogo} />
              </a>
            </SocialSignInBtn>
          </SocialSignInGroup>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Input
            id="login-gui_number-input"
            placeholder="統編"
            value={values.gui_number}
            style={{ padding: 12, marginBottom: 10 }}
            onChange={e => {
              const value = e.target.value;
              setValues(prev => ({ ...prev, gui_number: value }));
              setError(null);
            }}
          />
          <Input
            id="login-username-input"
            placeholder="信箱"
            value={values.username}
            style={{ padding: 12, marginBottom: 10 }}
            onChange={e => {
              const value = e.target.value;
              setValues(prev => ({ ...prev, username: value }));
              setError(null);
            }}
          />

          {/* <label htmlFor="login-password-input">密碼</label> */}
          <Input
            id="login-password-input"
            placeholder="密碼"
            type="password"
            value={values.password}
            style={{ padding: 12 }}
            onChange={e => {
              const value = e.target.value;
              setValues(prev => ({ ...prev, password: value }));
              setError(null);
            }}
          />

          <BottomBar>
            <Button
              type="primary"
              loading={loading}
              onClick={login}
              style={{ flex: 1, height: 40 }}
            >
              登入
            </Button>
          </BottomBar>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="link" style={{ display: "block" }}>
              <Link to="/fgpass" style={{ fontSize: 12, color: "#9b9b9b" }}>
                忘記密碼？
              </Link>
            </Button>
          </div>

          <div
            style={{ flex: 1, height: 1, backgroundColor: "darkgray" }}
          ></div>

          <div>
            原Social登入之企業會員，請切換到
            <span
              style={{ cursor: "pointer", color: "#9b9b9b" }}
              onClick={() => setUiState(UISTATE.normal)}
            >
              一般會員
            </span>
            下方的Google, Facebook登入。
          </div>
        </React.Fragment>
      )}
    </Modal>
  );
}

const BottomBar = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const SocialSignInGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const SocialSignInBtn = styled.div`
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 15px;

  & img {
    width: 60px;
    height: 60px;
  }

  ${props => props.css || ""}
`;

export default connect((state, ownProps) => ({}), ActionCreator, null, {
  forwardRef: true,
})(LoginModal);
