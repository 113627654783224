const navbarHeight = 80;
const navbarHeightMore = 70;
const tabHeight = 60;
const bgColorDark = "#141618";
const bgColorLight = "#efefef";
const textColorDark = "#black";
const textColorLight = "#ccc";
const lineColorDark = "#black";
const lineColorLight = "#ccc";
const breakpoints = {
  // mobile
  xs: 480,
  // pad
  lg: 992,
  // desktop
  xl: 1200, // seldom use
  // larger desktop
};

/*
antd spec:
{
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}
 */
const banner_ratio = 0.45681063122; // > 550 / 1024

const centerContentMaxWidth = "1320px";

const textTitleStyle = {
  fontSize: "24px",
  lineHeight: "46px",
  fontWeight: "300",
  color: "#3c3c3c",
};

const textContentStyle = {
  size: "14px",
  lineHeight: "26px",
  color: "#707070",
};

const bannerTagStyle = `
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 55px;
  width: 200px;
  left: -100px;
  bottom: 40px;

  @media only screen and (max-width: 1540px) {
    left: 20px;
  }

  @media only screen and (max-width: ${breakpoints.xl}px) {
    height: 40px;
    width: 156px;
  }
`;

const colors = {
  main: "#3c3c3c", // more darkgrey
  sub: "#f6f6f6", // lightgrey, input bkground
  text: "#707070", // darkgrey
  darkgrey: "#505050",
  selected: "#000000", // black
  unSelected: "#9b9b9b", // less darkgrey
  lighterGrey: "#d9d9d9",
  lightGrey: "#cccccc",
  textReverse: "#ffffff",
  hoverGreen: "#B5E2DA", // light green
  blueGreen: "#92b6ba",
  orangeRed: "#eb5554", // tag default
  border_grey: "#d9d9d9",
  red: "#aa3030",
  brown: "#a27f5c",
  lightGreen: "#d6e3e3",
  black: "#3c3c3c",
  eggShell: "#f3f2ed",
};

const rwdPadding = {
  huge: "100px",
  larger: "60px",
  large: "40px",
  medium: "30px",
  outer: "20px",
  inner: "15px",
};

export {
  navbarHeight,
  navbarHeightMore,
  bgColorDark,
  bgColorLight,
  textColorDark,
  textColorLight,
  lineColorDark,
  lineColorLight,
  breakpoints,
  colors,
  banner_ratio,
  textTitleStyle,
  textContentStyle,
  centerContentMaxWidth,
  rwdPadding,
  tabHeight,
  bannerTagStyle,
};
