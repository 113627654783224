import { message } from "antd";

class ErrBase extends Error {}
class ErrCart extends ErrBase {}
class ErrCartItem extends ErrBase {}
class ErrOrder extends ErrBase {}
class ErrQuote extends ErrBase {}
class ErrWorks extends ErrBase {}
class ErrLogin extends ErrBase {}
class ErrRegistor extends ErrBase {}
class ErrProfile extends ErrBase {}
class ErrInvoiceFormat extends ErrBase {}
class ErrCheckoutForm extends ErrBase {}
class ErrCoupon extends ErrBase {}

function errorHandler(ex, defaultMessage = "發生錯誤") {
  console.warn(ex);

  if (ex instanceof ErrBase) {
    message.error(ex.message);
  } else if (ex.error) {
    if (ex.error === "period_item_error") {
      message.error(
        "您的購物車同時包含：訂閱制商品與一般商品，請拆單分開下單。"
      );
    }
    if (ex.error === "items_limit") {
      message.error("購物車數量已滿。");
    }
  } else {
    message.error(defaultMessage);
  }
}

export {
  ErrCart,
  ErrCartItem,
  ErrOrder,
  ErrQuote,
  ErrWorks,
  ErrLogin,
  ErrRegistor,
  ErrProfile,
  errorHandler,
  ErrInvoiceFormat,
  ErrCheckoutForm,
  ErrCoupon,
};
