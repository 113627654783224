import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

// reference: https://github.com/gatsbyjs/gatsby/issues/2293

const StaticImage = ({ filename, style = {} }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Img
          fluid={
            data.allImageSharp.edges.find(element => {
              // Match string after final slash
              return element.node.fluid.src.split("/").pop() === filename;
            }).node.fluid
          }
          style={style}
          {...(style.objectFit ? { objectFit: style.objectFit } : {})}
        />
      );
    }}
  />
);

export default StaticImage;
