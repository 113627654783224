import React from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import useDimension from "../hooks/use-dimension";

const CopyrightLinks = () => {
  const { rwd } = useDimension();
  return (
    <>
      <StyledSpan rwd={rwd}>
        © Copyright Sensations Print All Rights Reserved.{" "}
      </StyledSpan>
      <StyledLink to="/privacy">隱私權政策</StyledLink> |{" "}
      <StyledLink to="/terms">服務條款</StyledLink>
    </>
  );
};

const StyledSpan = styled.span`
  display: inline-block;
  margin-right: 40px;

  ${props =>
    props.rwd === "mobile" &&
    css`
      display: block;
      margin-right: 0;
      margin-bottom: 10px;
    `}
`;

const StyledLink = styled(Link)`
  font-size: 12px;
  color: #6b6b6b;
  textdecoration: none;
`;

export default CopyrightLinks;
