const ApiProxyFactory = require("./ApiProxy");
const constants = require("./constants");

const ApiProxy = new ApiProxyFactory({ apiUrl: constants.apiUrl });

function transToQueryStr(params) {
  let query = "";
  if (typeof params === "object") {
    query = Object.keys(params)
      .filter(key => params[key] || params[key] === 0) // has value
      .reduce((e, key, idx) => {
        e = e + `${idx === 0 ? "?" : "&"}${key}=${params[key]}`;
        return e;
      }, "");
  }
  return query;
}

module.exports = {
  getToken: () => {
    return ApiProxy.apiToken;
  },

  setToken: token => {
    ApiProxy.setToken(token);
  },

  echo: async msg => {
    return msg;
  },

  //驗證信箱
  validEmail: ({ email }) => {
    //此api 也會 確認信箱有無已註冊

    return ApiProxy.post({
      path: `/api/user/profile/validation/request/`,
      data: {
        identity: email,
      },
    });
  },

  register: async ({ access_token, password }) => {
    return ApiProxy.post({
      path: `/api/user/register/`,
      data: {
        access_token,
        password,
      },
    });
  },

  enterpriseRegister: async ({ access_token, password, ...data }) => {
    return ApiProxy.post({
      path: `/api/user/ent/register/`,
      data: {
        access_token,
        password,
        ...data,
      },
    });
  },

  login: async ({ email, username, password }) => {
    return ApiProxy.post({
      path: `/api/user/login/`,
      data: {
        username,
        email,
        password,
      },
    });
  },

  loginEnterprise: async ({ username, password, gui_number }) => {
    return ApiProxy.post({
      path: `/api/user/ent/login/`,
      data: {
        gui_number,
        identity: username,
        password,
      },
    });
  },

  getMyProfile: async () => {
    return ApiProxy.get({
      path: `/api/user/profile/`,
    });
  },

  async editMyProfile(data) {
    if (data.avatar instanceof File) {
      let formData = new FormData();
      delete data.user;
      for (let key in data) {
        formData.append(key, data[key]);
      }

      return ApiProxy.formPut({
        path: "/api/user/profile/",
        formData,
      });
    } else {
      delete data.avatar;
      delete data.user;
      return ApiProxy.put({
        path: "/api/user/profile/",
        data,
      });
    }
  },

  async requestToUpgradeUserType(data) {
    return ApiProxy.post({
      path: `/auth/user/upgrade`,
      data,
    });
  },

  async getMyUpgradeUserTypeRecordList(data) {
    return ApiProxy.get({
      path: `/auth/user/upgrade/list`,
      data,
    });
  },

  async forgetPassword({ email }) {
    return ApiProxy.post({
      path: `/api/user/password/forgot/`,
      data: {
        email,
      },
    });
  },

  async changePassword({ username, password, newPassword }) {
    return ApiProxy.post({
      path: `/api/user/change_password/`,
      data: {
        username,
        password,
        new_password: newPassword,
      },
    });
  },

  async getJwtToken() {
    return ApiProxy.get({
      path: `/api/user/jwt/`,
    });
  },

  async searchProfile({ keyword }) {
    return ApiProxy.get({
      path: `/api/user/profile/all/?search=${keyword}`,
    });
  },

  async createCustomOrder({
    note,
    amount,
    cost,
    quantity,
    buyer,
    config,
    logistic_fee,
    payment_type,
  }) {
    return ApiProxy.post({
      path: `/api/order/custom/`,
      data: {
        item_amount: amount,
        item_cost: cost,
        item_quantity: quantity,
        logistic_fee,
        note,
        buyer,
        config,
        payment_type,
      },
    });
  },

  async adminUpdateOrder({ id, buyer }) {
    return ApiProxy.put({
      path: `/api/order/${id}/`,
      data: {
        buyer_id: buyer,
      },
    });
  },

  async contactFormSendEmail(data) {
    if (!data.data) {
      delete data.data;
    }
    return ApiProxy.post({
      path: `/api/contact/`,
      data,
    });
  },

  async voidOrder({ id, void_reason }) {
    return ApiProxy.post({
      secure: true,
      path: `/checkout/order/${id}/void/`,
      data: {
        void_reason,
      },
    });
  },

  async getLogistics(params) {
    let query = transToQueryStr(params);
    return ApiProxy.get({
      secure: true,
      path: `/api/logistics/${query}`,
    });
  },

  async getCustomCalculations(params) {
    return ApiProxy.post({
      secure: true,
      path: `/api/order/custom/calc/`,
      data: params,
    });
  },
};
