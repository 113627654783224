import React from "react";
import styled from "styled-components";
import * as Theme from "../Theme";
import { Context } from "../AppContext";

export default function ActivityIndicator() {
  const app = React.useContext(Context);

  const width = "100vw";
  const visible = app.state.loading;
  return (
    <>
      {visible && (
        <Wrapper visible={visible} color={Theme.colors.main} width={width}>
          <div className="spinner">
            <div className="line" />
          </div>
        </Wrapper>
      )}

      <Backdrop visible={visible} />
    </>
  );
}

const CoverAll = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: ${props => (props.visible ? "auto" : "none")};
`;

const Wrapper = styled(CoverAll)`
  z-index: 21;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  @keyframes spinner {
    0% {
      width: 0;
    }

    50% {
      width: ${props => props.width};
      transform: translateX(0);
    }

    100% {
      width: 0;
      transform: translateX(${props => props.width});
    }
  }
  & > .spinner {
    position: fixed;
    left: 0;
    right: 0;
    top: ${Theme.navbarHeight}px;

    & > .line {
      background-color: ${props => props.color};
      height: 2px;
      animation: spinner 5s ease-in-out infinite;
    }

    @media only screen and (max-width: ${Theme.breakpoints.lg}px) {
      top: ${Theme.navbarHeightMore}px;
    }
  }
`;

const Backdrop = styled(CoverAll)`
  z-index: 20;
  transition: 200ms;
  background-color: ${props => (props.visible ? "#fff5" : "transparent")};
`;
