import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "gatsby";
import * as Widget from "./Widget";
import ActionCreator from "../ActionCreator";
import * as Theme from "../Theme";
import * as Cart from "../Contexts/CartContext";
import Menu from "./Navbar.Menu";
import { Context } from "../AppContext";

const COLLAPSE_OPTIONS = {
  menu: 0,
  categories: 1,
};

const items = [
  {
    label: "線上訂購",
    subLabel: "SHOP",
    url: "/products",
  },
  { label: "印刷諮詢", subLabel: "QUOTE", url: "/quote" },
  {
    label: "印件分享",
    subLabel: "WORK",
    url: "/works",
  },
  { label: "感官快訊", subLabel: "BLOG", url: "/articles" },
  { label: "常見問題", subLabel: "FAQ", url: "/faq" },
  { label: `關於感官`, subLabel: "INFO", url: "/about" },
];

function Navbar(props) {
  const { cart } = props;
  const [collapsed, setCollapsed] = useState(null);
  const app = React.useContext(Context);
  const { profile } = app.state;

  return (
    <Style.Wrapper>
      <Style.NavBar>
        <Link to="/" className="site-title">
          {typeof window !== "undefined" &&
            window.innerWidth > Theme.breakpoints.xs && (
              <img
                src="/images/logo-desktop.png"
                alt="logo"
                height={45}
                width={178}
              />
            )}
          {typeof window !== "undefined" &&
            window.innerWidth <= Theme.breakpoints.xs && (
              <img
                src="/images/logo-phone.png"
                alt="logo"
                height={30}
                width={120}
              />
            )}
        </Link>
        <Row className="center">
          {items.map((item, idx) => (
            <Link key={idx} to={item.url} className="item">
              {item.label}
            </Link>
          ))}
        </Row>
        <Row>
          <Link to={profile ? "/orders" : "/profile"} className="item">
            {!profile ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: 5 }}>LOG IN</div>
                <img
                  src="/images/profile.svg"
                  alt="profile"
                  width="30"
                  height="auto"
                />
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: 5 }}>PROFILE</div>
                <img
                  src="/images/profile-dark.svg"
                  width="30"
                  height="auto"
                  alt="profile"
                />
              </div>
            )}
          </Link>
          <Link
            to="/cart"
            className="item"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ marginRight: 5 }}>CART</div>
            <Widget.Badge count={cart.data ? cart.data.items.length : 0} />
          </Link>
          {collapsed === COLLAPSE_OPTIONS.menu ? (
            <div className="item menu" onClick={() => setCollapsed(null)}>
              <img src="/images/close.png" alt="" width="30" height="auto" />
            </div>
          ) : (
            <div
              className="item menu"
              onClick={() => setCollapsed(COLLAPSE_OPTIONS.menu)}
            >
              <img src="/images/menu.png" alt="menu" width="30" height="auto" />
            </div>
          )}
        </Row>
      </Style.NavBar>
      <Menu
        items={items}
        show={collapsed === COLLAPSE_OPTIONS.menu}
        onClose={() => setCollapsed(null)}
      />
    </Style.Wrapper>
  );
}

const Style = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: ${Theme.navbarHeight}px;
    z-index: 15;
    background: white;
    box-shadow: 0px 2px 8px -4px #555;

    @media screen and (max-width: ${Theme.breakpoints.lg}px) {
      //pad
      height: ${Theme.navbarHeightMore}px;
    }
  `,

  NavBar: styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;
    height: 100%;
    padding: 0px 40px;

    @media screen and (max-width: ${Theme.breakpoints.xs}px) {
      padding: 0px 20px;
    }

    & > .center {
      flex: 1;
      justify-content: center;
      & > .item {
        margin-left: 20px;
      }

      @media screen and (max-width: ${Theme.breakpoints.lg}px) {
        & > * {
          display: none;
        }
      }
    }
  `,
};

const Row = styled.div`
  display: flex;
  align-items: center;

  & > .item {
    padding: 4px;
    margin-left: 5px;
    cursor: pointer;
    position: relative;
    &:hover {
      opacity: 0.5;
    }
  }

  & > .menu {
    width: 60px;
    text-align: right;
    @media screen and (max-width: ${Theme.breakpoints.xs}px) {
      padding-right: 0px;
      text-align: left;
      width: 30px;
    }
  }
`;

export default connect(
  (state, ownProps) => ({}),
  ActionCreator
)(Cart.withConsumer(Navbar));
