import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
    console.warn("*** Error Boundary ***", error, info);
  }

  render() {
    let { error } = this.state;

    if (error) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ padding: 20, backgroundColor: "grey" }}>
            <h2 style={{ color: "white" }}>Oops! We have errors!</h2>
            <p style={{ color: "#ddd" }}>Please reload the page</p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
