export const zip3 = [
  {
    zip: "100",
    city: "臺北市",
    district: "中正區",
  },
  {
    zip: "103",
    city: "臺北市",
    district: "大同區",
  },
  {
    zip: "104",
    city: "臺北市",
    district: "中山區",
  },
  {
    zip: "105",
    city: "臺北市",
    district: "松山區",
  },
  {
    zip: "106",
    city: "臺北市",
    district: "大安區",
  },
  {
    zip: "108",
    city: "臺北市",
    district: "萬華區",
  },
  {
    zip: "110",
    city: "臺北市",
    district: "信義區",
  },
  {
    zip: "111",
    city: "臺北市",
    district: "士林區",
  },
  {
    zip: "112",
    city: "臺北市",
    district: "北投區",
  },
  {
    zip: "114",
    city: "臺北市",
    district: "內湖區",
  },
  {
    zip: "115",
    city: "臺北市",
    district: "南港區",
  },
  {
    zip: "116",
    city: "臺北市",
    district: "文山區",
  },
  {
    zip: "200",
    city: "基隆市",
    district: "仁愛區",
  },
  {
    zip: "201",
    city: "基隆市",
    district: "信義區",
  },
  {
    zip: "202",
    city: "基隆市",
    district: "中正區",
  },
  {
    zip: "203",
    city: "基隆市",
    district: "中山區",
  },
  {
    zip: "204",
    city: "基隆市",
    district: "安樂區",
  },
  {
    zip: "205",
    city: "基隆市",
    district: "暖暖區",
  },
  {
    zip: "206",
    city: "基隆市",
    district: "七堵區",
  },
  {
    zip: "207",
    city: "新北市",
    district: "萬里區",
  },
  {
    zip: "208",
    city: "新北市",
    district: "金山區",
  },
  {
    zip: "220",
    city: "新北市",
    district: "板橋區",
  },
  {
    zip: "221",
    city: "新北市",
    district: "汐止區",
  },
  {
    zip: "222",
    city: "新北市",
    district: "深坑區",
  },
  {
    zip: "223",
    city: "新北市",
    district: "石碇區",
  },
  {
    zip: "224",
    city: "新北市",
    district: "瑞芳區",
  },
  {
    zip: "226",
    city: "新北市",
    district: "平溪區",
  },
  {
    zip: "227",
    city: "新北市",
    district: "雙溪區",
  },
  {
    zip: "228",
    city: "新北市",
    district: "貢寮區",
  },
  {
    zip: "231",
    city: "新北市",
    district: "新店區",
  },
  {
    zip: "232",
    city: "新北市",
    district: "坪林區",
  },
  {
    zip: "233",
    city: "新北市",
    district: "烏來區",
  },
  {
    zip: "234",
    city: "新北市",
    district: "永和區",
  },
  {
    zip: "235",
    city: "新北市",
    district: "中和區",
  },
  {
    zip: "236",
    city: "新北市",
    district: "土城區",
  },
  {
    zip: "237",
    city: "新北市",
    district: "三峽區",
  },
  {
    zip: "238",
    city: "新北市",
    district: "樹林區",
  },
  {
    zip: "239",
    city: "新北市",
    district: "鶯歌區",
  },
  {
    zip: "241",
    city: "新北市",
    district: "三重區",
  },
  {
    zip: "242",
    city: "新北市",
    district: "新莊區",
  },
  {
    zip: "243",
    city: "新北市",
    district: "泰山區",
  },
  {
    zip: "244",
    city: "新北市",
    district: "林口區",
  },
  {
    zip: "247",
    city: "新北市",
    district: "蘆洲區",
  },
  {
    zip: "248",
    city: "新北市",
    district: "五股區",
  },
  {
    zip: "249",
    city: "新北市",
    district: "八里區",
  },
  {
    zip: "251",
    city: "新北市",
    district: "淡水區",
  },
  {
    zip: "252",
    city: "新北市",
    district: "三芝區",
  },
  {
    zip: "253",
    city: "新北市",
    district: "石門區",
  },
  {
    zip: "209",
    city: "連江縣",
    district: "南竿鄉",
  },
  {
    zip: "210",
    city: "連江縣",
    district: "北竿鄉",
  },
  {
    zip: "211",
    city: "連江縣",
    district: "莒光鄉",
  },
  {
    zip: "212",
    city: "連江縣",
    district: "東引鄉",
  },
  {
    zip: "260",
    city: "宜蘭縣",
    district: "宜蘭市",
  },
  {
    zip: "260",
    city: "宜蘭縣",
    district: "壯圍鄉",
  },
  {
    zip: "261",
    city: "宜蘭縣",
    district: "頭城鎮",
  },
  {
    zip: "262",
    city: "宜蘭縣",
    district: "礁溪鄉",
  },
  {
    zip: "264",
    city: "宜蘭縣",
    district: "員山鄉",
  },
  {
    zip: "265",
    city: "宜蘭縣",
    district: "羅東鎮",
  },
  {
    zip: "266",
    city: "宜蘭縣",
    district: "三星鄉",
  },
  {
    zip: "267",
    city: "宜蘭縣",
    district: "大同鄉",
  },
  {
    zip: "268",
    city: "宜蘭縣",
    district: "五結鄉",
  },
  {
    zip: "269",
    city: "宜蘭縣",
    district: "冬山鄉",
  },
  {
    zip: "270",
    city: "宜蘭縣",
    district: "蘇澳鎮",
  },
  {
    zip: "272",
    city: "宜蘭縣",
    district: "南澳鄉",
  },
  {
    zip: "290",
    city: "宜蘭縣",
    district: "釣魚臺",
  },
  {
    zip: "290",
    city: "釣魚臺",
    district: "釣魚臺",
  },
  {
    zip: "300",
    city: "新竹市",
    district: "東區",
  },
  {
    zip: "300",
    city: "新竹市",
    district: "北區",
  },
  {
    zip: "300",
    city: "新竹市",
    district: "香山區",
  },
  {
    zip: "300",
    city: "新竹縣",
    district: "寶山鄉",
  },
  {
    zip: "302",
    city: "新竹縣",
    district: "竹北市",
  },
  {
    zip: "303",
    city: "新竹縣",
    district: "湖口鄉",
  },
  {
    zip: "304",
    city: "新竹縣",
    district: "新豐鄉",
  },
  {
    zip: "305",
    city: "新竹縣",
    district: "新埔鎮",
  },
  {
    zip: "306",
    city: "新竹縣",
    district: "關西鎮",
  },
  {
    zip: "307",
    city: "新竹縣",
    district: "芎林鄉",
  },
  {
    zip: "310",
    city: "新竹縣",
    district: "竹東鎮",
  },
  {
    zip: "311",
    city: "新竹縣",
    district: "五峰鄉",
  },
  {
    zip: "312",
    city: "新竹縣",
    district: "橫山鄉",
  },
  {
    zip: "313",
    city: "新竹縣",
    district: "尖石鄉",
  },
  {
    zip: "314",
    city: "新竹縣",
    district: "北埔鄉",
  },
  {
    zip: "315",
    city: "新竹縣",
    district: "峨眉鄉",
  },
  {
    zip: "320",
    city: "桃園市",
    district: "中壢區",
  },
  {
    zip: "324",
    city: "桃園市",
    district: "平鎮區",
  },
  {
    zip: "325",
    city: "桃園市",
    district: "龍潭區",
  },
  {
    zip: "326",
    city: "桃園市",
    district: "楊梅區",
  },
  {
    zip: "327",
    city: "桃園市",
    district: "新屋區",
  },
  {
    zip: "328",
    city: "桃園市",
    district: "觀音區",
  },
  {
    zip: "330",
    city: "桃園市",
    district: "桃園區",
  },
  {
    zip: "333",
    city: "桃園市",
    district: "龜山區",
  },
  {
    zip: "334",
    city: "桃園市",
    district: "八德區",
  },
  {
    zip: "335",
    city: "桃園市",
    district: "大溪區",
  },
  {
    zip: "336",
    city: "桃園市",
    district: "復興區",
  },
  {
    zip: "337",
    city: "桃園市",
    district: "大園區",
  },
  {
    zip: "338",
    city: "桃園市",
    district: "蘆竹區",
  },
  {
    zip: "350",
    city: "苗栗縣",
    district: "竹南鎮",
  },
  {
    zip: "351",
    city: "苗栗縣",
    district: "頭份市",
  },
  {
    zip: "352",
    city: "苗栗縣",
    district: "三灣鄉",
  },
  {
    zip: "353",
    city: "苗栗縣",
    district: "南庄鄉",
  },
  {
    zip: "354",
    city: "苗栗縣",
    district: "獅潭鄉",
  },
  {
    zip: "356",
    city: "苗栗縣",
    district: "後龍鎮",
  },
  {
    zip: "357",
    city: "苗栗縣",
    district: "通霄鎮",
  },
  {
    zip: "358",
    city: "苗栗縣",
    district: "苑裡鎮",
  },
  {
    zip: "360",
    city: "苗栗縣",
    district: "苗栗市",
  },
  {
    zip: "361",
    city: "苗栗縣",
    district: "造橋鄉",
  },
  {
    zip: "362",
    city: "苗栗縣",
    district: "頭屋鄉",
  },
  {
    zip: "363",
    city: "苗栗縣",
    district: "公館鄉",
  },
  {
    zip: "364",
    city: "苗栗縣",
    district: "大湖鄉",
  },
  {
    zip: "365",
    city: "苗栗縣",
    district: "泰安鄉",
  },
  {
    zip: "366",
    city: "苗栗縣",
    district: "銅鑼鄉",
  },
  {
    zip: "367",
    city: "苗栗縣",
    district: "三義鄉",
  },
  {
    zip: "368",
    city: "苗栗縣",
    district: "西湖鄉",
  },
  {
    zip: "369",
    city: "苗栗縣",
    district: "卓蘭鎮",
  },
  {
    zip: "400",
    city: "臺中市",
    district: "中區",
  },
  {
    zip: "401",
    city: "臺中市",
    district: "東區",
  },
  {
    zip: "402",
    city: "臺中市",
    district: "南區",
  },
  {
    zip: "403",
    city: "臺中市",
    district: "西區",
  },
  {
    zip: "404",
    city: "臺中市",
    district: "北區",
  },
  {
    zip: "406",
    city: "臺中市",
    district: "北屯區",
  },
  {
    zip: "407",
    city: "臺中市",
    district: "西屯區",
  },
  {
    zip: "408",
    city: "臺中市",
    district: "南屯區",
  },
  {
    zip: "411",
    city: "臺中市",
    district: "太平區",
  },
  {
    zip: "412",
    city: "臺中市",
    district: "大里區",
  },
  {
    zip: "413",
    city: "臺中市",
    district: "霧峰區",
  },
  {
    zip: "414",
    city: "臺中市",
    district: "烏日區",
  },
  {
    zip: "420",
    city: "臺中市",
    district: "豐原區",
  },
  {
    zip: "421",
    city: "臺中市",
    district: "后里區",
  },
  {
    zip: "422",
    city: "臺中市",
    district: "石岡區",
  },
  {
    zip: "423",
    city: "臺中市",
    district: "東勢區",
  },
  {
    zip: "424",
    city: "臺中市",
    district: "和平區",
  },
  {
    zip: "426",
    city: "臺中市",
    district: "新社區",
  },
  {
    zip: "427",
    city: "臺中市",
    district: "潭子區",
  },
  {
    zip: "428",
    city: "臺中市",
    district: "大雅區",
  },
  {
    zip: "429",
    city: "臺中市",
    district: "神岡區",
  },
  {
    zip: "432",
    city: "臺中市",
    district: "大肚區",
  },
  {
    zip: "433",
    city: "臺中市",
    district: "沙鹿區",
  },
  {
    zip: "434",
    city: "臺中市",
    district: "龍井區",
  },
  {
    zip: "435",
    city: "臺中市",
    district: "梧棲區",
  },
  {
    zip: "436",
    city: "臺中市",
    district: "清水區",
  },
  {
    zip: "437",
    city: "臺中市",
    district: "大甲區",
  },
  {
    zip: "438",
    city: "臺中市",
    district: "外埔區",
  },
  {
    zip: "439",
    city: "臺中市",
    district: "大安區",
  },
  {
    zip: "500",
    city: "彰化縣",
    district: "彰化市",
  },
  {
    zip: "502",
    city: "彰化縣",
    district: "芬園鄉",
  },
  {
    zip: "503",
    city: "彰化縣",
    district: "花壇鄉",
  },
  {
    zip: "504",
    city: "彰化縣",
    district: "秀水鄉",
  },
  {
    zip: "505",
    city: "彰化縣",
    district: "鹿港鎮",
  },
  {
    zip: "506",
    city: "彰化縣",
    district: "福興鄉",
  },
  {
    zip: "507",
    city: "彰化縣",
    district: "線西鄉",
  },
  {
    zip: "508",
    city: "彰化縣",
    district: "和美鎮",
  },
  {
    zip: "509",
    city: "彰化縣",
    district: "伸港鄉",
  },
  {
    zip: "510",
    city: "彰化縣",
    district: "員林市",
  },
  {
    zip: "511",
    city: "彰化縣",
    district: "社頭鄉",
  },
  {
    zip: "512",
    city: "彰化縣",
    district: "永靖鄉",
  },
  {
    zip: "513",
    city: "彰化縣",
    district: "埔心鄉",
  },
  {
    zip: "514",
    city: "彰化縣",
    district: "溪湖鎮",
  },
  {
    zip: "515",
    city: "彰化縣",
    district: "大村鄉",
  },
  {
    zip: "516",
    city: "彰化縣",
    district: "埔鹽鄉",
  },
  {
    zip: "520",
    city: "彰化縣",
    district: "田中鎮",
  },
  {
    zip: "521",
    city: "彰化縣",
    district: "北斗鎮",
  },
  {
    zip: "522",
    city: "彰化縣",
    district: "田尾鄉",
  },
  {
    zip: "523",
    city: "彰化縣",
    district: "埤頭鄉",
  },
  {
    zip: "524",
    city: "彰化縣",
    district: "溪州鄉",
  },
  {
    zip: "525",
    city: "彰化縣",
    district: "竹塘鄉",
  },
  {
    zip: "526",
    city: "彰化縣",
    district: "二林鎮",
  },
  {
    zip: "527",
    city: "彰化縣",
    district: "大城鄉",
  },
  {
    zip: "528",
    city: "彰化縣",
    district: "芳苑鄉",
  },
  {
    zip: "530",
    city: "彰化縣",
    district: "二水鄉",
  },
  {
    zip: "540",
    city: "南投縣",
    district: "南投市",
  },
  {
    zip: "541",
    city: "南投縣",
    district: "中寮鄉",
  },
  {
    zip: "542",
    city: "南投縣",
    district: "草屯鎮",
  },
  {
    zip: "544",
    city: "南投縣",
    district: "國姓鄉",
  },
  {
    zip: "545",
    city: "南投縣",
    district: "埔里鎮",
  },
  {
    zip: "546",
    city: "南投縣",
    district: "仁愛鄉",
  },
  {
    zip: "551",
    city: "南投縣",
    district: "名間鄉",
  },
  {
    zip: "552",
    city: "南投縣",
    district: "集集鎮",
  },
  {
    zip: "553",
    city: "南投縣",
    district: "水里鄉",
  },
  {
    zip: "555",
    city: "南投縣",
    district: "魚池鄉",
  },
  {
    zip: "556",
    city: "南投縣",
    district: "信義鄉",
  },
  {
    zip: "557",
    city: "南投縣",
    district: "竹山鎮",
  },
  {
    zip: "558",
    city: "南投縣",
    district: "鹿谷鄉",
  },
  {
    zip: "600",
    city: "嘉義市",
    district: "西區",
  },
  {
    zip: "600",
    city: "嘉義市",
    district: "東區",
  },
  {
    zip: "602",
    city: "嘉義縣",
    district: "番路鄉",
  },
  {
    zip: "603",
    city: "嘉義縣",
    district: "梅山鄉",
  },
  {
    zip: "604",
    city: "嘉義縣",
    district: "竹崎鄉",
  },
  {
    zip: "605",
    city: "嘉義縣",
    district: "阿里山鄉",
  },
  {
    zip: "606",
    city: "嘉義縣",
    district: "中埔鄉",
  },
  {
    zip: "607",
    city: "嘉義縣",
    district: "大埔鄉",
  },
  {
    zip: "608",
    city: "嘉義縣",
    district: "水上鄉",
  },
  {
    zip: "611",
    city: "嘉義縣",
    district: "鹿草鄉",
  },
  {
    zip: "612",
    city: "嘉義縣",
    district: "太保市",
  },
  {
    zip: "613",
    city: "嘉義縣",
    district: "朴子市",
  },
  {
    zip: "614",
    city: "嘉義縣",
    district: "東石鄉",
  },
  {
    zip: "615",
    city: "嘉義縣",
    district: "六腳鄉",
  },
  {
    zip: "616",
    city: "嘉義縣",
    district: "新港鄉",
  },
  {
    zip: "621",
    city: "嘉義縣",
    district: "民雄鄉",
  },
  {
    zip: "622",
    city: "嘉義縣",
    district: "大林鎮",
  },
  {
    zip: "623",
    city: "嘉義縣",
    district: "溪口鄉",
  },
  {
    zip: "624",
    city: "嘉義縣",
    district: "義竹鄉",
  },
  {
    zip: "625",
    city: "嘉義縣",
    district: "布袋鎮",
  },
  {
    zip: "630",
    city: "雲林縣",
    district: "斗南鎮",
  },
  {
    zip: "631",
    city: "雲林縣",
    district: "大埤鄉",
  },
  {
    zip: "632",
    city: "雲林縣",
    district: "虎尾鎮",
  },
  {
    zip: "633",
    city: "雲林縣",
    district: "土庫鎮",
  },
  {
    zip: "634",
    city: "雲林縣",
    district: "褒忠鄉",
  },
  {
    zip: "635",
    city: "雲林縣",
    district: "東勢鄉",
  },
  {
    zip: "636",
    city: "雲林縣",
    district: "臺西鄉",
  },
  {
    zip: "637",
    city: "雲林縣",
    district: "崙背鄉",
  },
  {
    zip: "638",
    city: "雲林縣",
    district: "麥寮鄉",
  },
  {
    zip: "640",
    city: "雲林縣",
    district: "斗六市",
  },
  {
    zip: "643",
    city: "雲林縣",
    district: "林內鄉",
  },
  {
    zip: "646",
    city: "雲林縣",
    district: "古坑鄉",
  },
  {
    zip: "647",
    city: "雲林縣",
    district: "莿桐鄉",
  },
  {
    zip: "648",
    city: "雲林縣",
    district: "西螺鎮",
  },
  {
    zip: "649",
    city: "雲林縣",
    district: "二崙鄉",
  },
  {
    zip: "651",
    city: "雲林縣",
    district: "北港鎮",
  },
  {
    zip: "652",
    city: "雲林縣",
    district: "水林鄉",
  },
  {
    zip: "653",
    city: "雲林縣",
    district: "口湖鄉",
  },
  {
    zip: "654",
    city: "雲林縣",
    district: "四湖鄉",
  },
  {
    zip: "655",
    city: "雲林縣",
    district: "元長鄉",
  },
  {
    zip: "700",
    city: "臺南市",
    district: "中西區",
  },
  {
    zip: "701",
    city: "臺南市",
    district: "東區",
  },
  {
    zip: "702",
    city: "臺南市",
    district: "南區",
  },
  {
    zip: "704",
    city: "臺南市",
    district: "北區",
  },
  {
    zip: "708",
    city: "臺南市",
    district: "安平區",
  },
  {
    zip: "709",
    city: "臺南市",
    district: "安南區",
  },
  {
    zip: "710",
    city: "臺南市",
    district: "永康區",
  },
  {
    zip: "711",
    city: "臺南市",
    district: "歸仁區",
  },
  {
    zip: "712",
    city: "臺南市",
    district: "新化區",
  },
  {
    zip: "713",
    city: "臺南市",
    district: "左鎮區",
  },
  {
    zip: "714",
    city: "臺南市",
    district: "玉井區",
  },
  {
    zip: "715",
    city: "臺南市",
    district: "楠西區",
  },
  {
    zip: "716",
    city: "臺南市",
    district: "南化區",
  },
  {
    zip: "717",
    city: "臺南市",
    district: "仁德區",
  },
  {
    zip: "718",
    city: "臺南市",
    district: "關廟區",
  },
  {
    zip: "719",
    city: "臺南市",
    district: "龍崎區",
  },
  {
    zip: "720",
    city: "臺南市",
    district: "官田區",
  },
  {
    zip: "721",
    city: "臺南市",
    district: "麻豆區",
  },
  {
    zip: "722",
    city: "臺南市",
    district: "佳里區",
  },
  {
    zip: "723",
    city: "臺南市",
    district: "西港區",
  },
  {
    zip: "724",
    city: "臺南市",
    district: "七股區",
  },
  {
    zip: "725",
    city: "臺南市",
    district: "將軍區",
  },
  {
    zip: "726",
    city: "臺南市",
    district: "學甲區",
  },
  {
    zip: "727",
    city: "臺南市",
    district: "北門區",
  },
  {
    zip: "730",
    city: "臺南市",
    district: "新營區",
  },
  {
    zip: "731",
    city: "臺南市",
    district: "後壁區",
  },
  {
    zip: "732",
    city: "臺南市",
    district: "白河區",
  },
  {
    zip: "733",
    city: "臺南市",
    district: "東山區",
  },
  {
    zip: "734",
    city: "臺南市",
    district: "六甲區",
  },
  {
    zip: "735",
    city: "臺南市",
    district: "下營區",
  },
  {
    zip: "736",
    city: "臺南市",
    district: "柳營區",
  },
  {
    zip: "737",
    city: "臺南市",
    district: "鹽水區",
  },
  {
    zip: "741",
    city: "臺南市",
    district: "善化區",
  },
  {
    zip: "741",
    city: "臺南市",
    district: "新市區",
  },
  {
    zip: "742",
    city: "臺南市",
    district: "大內區",
  },
  {
    zip: "743",
    city: "臺南市",
    district: "山上區",
  },
  {
    zip: "745",
    city: "臺南市",
    district: "安定區",
  },
  {
    zip: "800",
    city: "高雄市",
    district: "新興區",
  },
  {
    zip: "801",
    city: "高雄市",
    district: "前金區",
  },
  {
    zip: "802",
    city: "高雄市",
    district: "苓雅區",
  },
  {
    zip: "803",
    city: "高雄市",
    district: "鹽埕區",
  },
  {
    zip: "804",
    city: "高雄市",
    district: "鼓山區",
  },
  {
    zip: "805",
    city: "高雄市",
    district: "旗津區",
  },
  {
    zip: "806",
    city: "高雄市",
    district: "前鎮區",
  },
  {
    zip: "807",
    city: "高雄市",
    district: "三民區",
  },
  {
    zip: "811",
    city: "高雄市",
    district: "楠梓區",
  },
  {
    zip: "812",
    city: "高雄市",
    district: "小港區",
  },
  {
    zip: "813",
    city: "高雄市",
    district: "左營區",
  },
  {
    zip: "814",
    city: "高雄市",
    district: "仁武區",
  },
  {
    zip: "815",
    city: "高雄市",
    district: "大社區",
  },
  {
    zip: "817",
    city: "高雄市",
    district: "東沙群島",
  },
  {
    zip: "819",
    city: "高雄市",
    district: "南沙群島",
  },
  {
    zip: "820",
    city: "高雄市",
    district: "岡山區",
  },
  {
    zip: "821",
    city: "高雄市",
    district: "路竹區",
  },
  {
    zip: "822",
    city: "高雄市",
    district: "阿蓮區",
  },
  {
    zip: "823",
    city: "高雄市",
    district: "田寮區",
  },
  {
    zip: "824",
    city: "高雄市",
    district: "燕巢區",
  },
  {
    zip: "825",
    city: "高雄市",
    district: "橋頭區",
  },
  {
    zip: "826",
    city: "高雄市",
    district: "梓官區",
  },
  {
    zip: "827",
    city: "高雄市",
    district: "彌陀區",
  },
  {
    zip: "828",
    city: "高雄市",
    district: "永安區",
  },
  {
    zip: "829",
    city: "高雄市",
    district: "湖內區",
  },
  {
    zip: "830",
    city: "高雄市",
    district: "鳳山區",
  },
  {
    zip: "831",
    city: "高雄市",
    district: "大寮區",
  },
  {
    zip: "832",
    city: "高雄市",
    district: "林園區",
  },
  {
    zip: "833",
    city: "高雄市",
    district: "鳥松區",
  },
  {
    zip: "840",
    city: "高雄市",
    district: "大樹區",
  },
  {
    zip: "842",
    city: "高雄市",
    district: "旗山區",
  },
  {
    zip: "843",
    city: "高雄市",
    district: "美濃區",
  },
  {
    zip: "844",
    city: "高雄市",
    district: "六龜區",
  },
  {
    zip: "845",
    city: "高雄市",
    district: "內門區",
  },
  {
    zip: "846",
    city: "高雄市",
    district: "杉林區",
  },
  {
    zip: "847",
    city: "高雄市",
    district: "甲仙區",
  },
  {
    zip: "848",
    city: "高雄市",
    district: "桃源區",
  },
  {
    zip: "849",
    city: "高雄市",
    district: "那瑪夏區",
  },
  {
    zip: "851",
    city: "高雄市",
    district: "茂林區",
  },
  {
    zip: "852",
    city: "高雄市",
    district: "茄萣區",
  },
  {
    zip: "817",
    city: "南海島",
    district: "東沙群島",
  },
  {
    zip: "819",
    city: "南海島",
    district: "南沙群島",
  },
  {
    zip: "880",
    city: "澎湖縣",
    district: "馬公市",
  },
  {
    zip: "881",
    city: "澎湖縣",
    district: "西嶼鄉",
  },
  {
    zip: "882",
    city: "澎湖縣",
    district: "望安鄉",
  },
  {
    zip: "883",
    city: "澎湖縣",
    district: "七美鄉",
  },
  {
    zip: "884",
    city: "澎湖縣",
    district: "白沙鄉",
  },
  {
    zip: "885",
    city: "澎湖縣",
    district: "湖西鄉",
  },
  {
    zip: "890",
    city: "金門縣",
    district: "金沙鎮",
  },
  {
    zip: "891",
    city: "金門縣",
    district: "金湖鎮",
  },
  {
    zip: "892",
    city: "金門縣",
    district: "金寧鄉",
  },
  {
    zip: "893",
    city: "金門縣",
    district: "金城鎮",
  },
  {
    zip: "894",
    city: "金門縣",
    district: "烈嶼鄉",
  },
  {
    zip: "896",
    city: "金門縣",
    district: "烏坵鄉",
  },
  {
    zip: "900",
    city: "屏東縣",
    district: "屏東市",
  },
  {
    zip: "901",
    city: "屏東縣",
    district: "三地門鄉",
  },
  {
    zip: "902",
    city: "屏東縣",
    district: "霧臺鄉",
  },
  {
    zip: "903",
    city: "屏東縣",
    district: "瑪家鄉",
  },
  {
    zip: "904",
    city: "屏東縣",
    district: "九如鄉",
  },
  {
    zip: "905",
    city: "屏東縣",
    district: "里港鄉",
  },
  {
    zip: "906",
    city: "屏東縣",
    district: "高樹鄉",
  },
  {
    zip: "907",
    city: "屏東縣",
    district: "鹽埔鄉",
  },
  {
    zip: "908",
    city: "屏東縣",
    district: "長治鄉",
  },
  {
    zip: "909",
    city: "屏東縣",
    district: "麟洛鄉",
  },
  {
    zip: "911",
    city: "屏東縣",
    district: "竹田鄉",
  },
  {
    zip: "912",
    city: "屏東縣",
    district: "內埔鄉",
  },
  {
    zip: "913",
    city: "屏東縣",
    district: "萬丹鄉",
  },
  {
    zip: "920",
    city: "屏東縣",
    district: "潮州鎮",
  },
  {
    zip: "921",
    city: "屏東縣",
    district: "泰武鄉",
  },
  {
    zip: "922",
    city: "屏東縣",
    district: "來義鄉",
  },
  {
    zip: "923",
    city: "屏東縣",
    district: "萬巒鄉",
  },
  {
    zip: "924",
    city: "屏東縣",
    district: "崁頂鄉",
  },
  {
    zip: "925",
    city: "屏東縣",
    district: "新埤鄉",
  },
  {
    zip: "926",
    city: "屏東縣",
    district: "南州鄉",
  },
  {
    zip: "927",
    city: "屏東縣",
    district: "林邊鄉",
  },
  {
    zip: "928",
    city: "屏東縣",
    district: "東港鎮",
  },
  {
    zip: "929",
    city: "屏東縣",
    district: "琉球鄉",
  },
  {
    zip: "931",
    city: "屏東縣",
    district: "佳冬鄉",
  },
  {
    zip: "932",
    city: "屏東縣",
    district: "新園鄉",
  },
  {
    zip: "940",
    city: "屏東縣",
    district: "枋寮鄉",
  },
  {
    zip: "941",
    city: "屏東縣",
    district: "枋山鄉",
  },
  {
    zip: "942",
    city: "屏東縣",
    district: "春日鄉",
  },
  {
    zip: "943",
    city: "屏東縣",
    district: "獅子鄉",
  },
  {
    zip: "944",
    city: "屏東縣",
    district: "車城鄉",
  },
  {
    zip: "945",
    city: "屏東縣",
    district: "牡丹鄉",
  },
  {
    zip: "946",
    city: "屏東縣",
    district: "恆春鎮",
  },
  {
    zip: "947",
    city: "屏東縣",
    district: "滿州鄉",
  },
  {
    zip: "950",
    city: "臺東縣",
    district: "臺東市",
  },
  {
    zip: "951",
    city: "臺東縣",
    district: "綠島鄉",
  },
  {
    zip: "952",
    city: "臺東縣",
    district: "蘭嶼鄉",
  },
  {
    zip: "953",
    city: "臺東縣",
    district: "延平鄉",
  },
  {
    zip: "954",
    city: "臺東縣",
    district: "卑南鄉",
  },
  {
    zip: "955",
    city: "臺東縣",
    district: "鹿野鄉",
  },
  {
    zip: "956",
    city: "臺東縣",
    district: "關山鎮",
  },
  {
    zip: "957",
    city: "臺東縣",
    district: "海端鄉",
  },
  {
    zip: "958",
    city: "臺東縣",
    district: "池上鄉",
  },
  {
    zip: "959",
    city: "臺東縣",
    district: "東河鄉",
  },
  {
    zip: "961",
    city: "臺東縣",
    district: "成功鎮",
  },
  {
    zip: "962",
    city: "臺東縣",
    district: "長濱鄉",
  },
  {
    zip: "963",
    city: "臺東縣",
    district: "太麻里鄉",
  },
  {
    zip: "964",
    city: "臺東縣",
    district: "金峰鄉",
  },
  {
    zip: "965",
    city: "臺東縣",
    district: "大武鄉",
  },
  {
    zip: "966",
    city: "臺東縣",
    district: "達仁鄉",
  },
  {
    zip: "970",
    city: "花蓮縣",
    district: "花蓮市",
  },
  {
    zip: "971",
    city: "花蓮縣",
    district: "新城鄉",
  },
  {
    zip: "972",
    city: "花蓮縣",
    district: "秀林鄉",
  },
  {
    zip: "973",
    city: "花蓮縣",
    district: "吉安鄉",
  },
  {
    zip: "974",
    city: "花蓮縣",
    district: "壽豐鄉",
  },
  {
    zip: "975",
    city: "花蓮縣",
    district: "鳳林鎮",
  },
  {
    zip: "976",
    city: "花蓮縣",
    district: "光復鄉",
  },
  {
    zip: "977",
    city: "花蓮縣",
    district: "豐濱鄉",
  },
  {
    zip: "978",
    city: "花蓮縣",
    district: "瑞穗鄉",
  },
  {
    zip: "979",
    city: "花蓮縣",
    district: "萬榮鄉",
  },
  {
    zip: "981",
    city: "花蓮縣",
    district: "玉里鎮",
  },
  {
    zip: "982",
    city: "花蓮縣",
    district: "卓溪鄉",
  },
  {
    zip: "983",
    city: "花蓮縣",
    district: "富里鄉",
  },
];
