const Catch = fn =>
  function(...args) {
    try {
      fn.apply(this, args);
    } catch (e) {
      console.error("GA error:", e);
    }
    return;
  };

const GA = {
  addToCart: Catch(function(product, qty) {
    window.gtag("event", "add_to_cart", {
      currency: "TWD",
      value: product.price,
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          price: product.price,
          quantity: qty,
        },
      ],
    });
  }),
  removeFromCart: Catch(function(product, qty) {
    window.gtag("event", "remove_from_cart", {
      currency: "TWD",
      value: product.price,
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          price: product.price,
          quantity: qty,
        },
      ],
    });
  }),
  purchase: Catch(function(items = [], order = {}, event_id) {
    window.gtag("event", "purchase", {
      currency: "TWD",
      transaction_id: event_id,
      value: order.amount,
      tax: order.tax,
      shipping: order.shipping,
      coupon: order.coupon,
      items: items.map(item => {
        let q1 = parseInt(item.config.quantity_select),
          q2 = parseInt(item.quantity);
        let q = q1 > q2 ? q1 : q2;
        const price = parseFloat(parseFloat(item.price / q).toFixed(2));

        return {
          item_id: item.name,
          item_name: item.name,
          price,
          quantity: q,
        };
      }),
    });
  }),
  addImpression: Catch(function(item) {
    window.gtag("event", "view_item", {
      currency: "TWD",
      value: 0,
      items: [
        {
          item_id: item.id,
          item_name: item.name,
        },
      ],
    });
  }),
  checkout: Catch(function(order, cart) {
    // TODO parameter
    const _items = cart ? cart.items : [];
    const items = _items.forEach(item => {
      let q1 = parseInt(item.config.quantity_select),
        q2 = parseInt(item.quantity);
      let q = q1 > q2 ? q1 : q2;
      const price = parseFloat(parseFloat(item.price / q).toFixed(2));

      return {
        item_id: item.name,
        item_name: item.name,
        price,
        quantity: q,
      };
    });
    window.gtag("event", "add_payment_info", {
      currency: "TWD",
      value: order.amount,
      coupon: order.coupon,
      payment_type: order.payment_type + "_" + order.payment_subtype,
      items,
    });

    window.gtag("event", "begin_checkout", {
      currency: "TWD",
      value: order.amount,
      coupon: order.coupon,
      items,
    });
  }),
  addPromotionImpression: Catch(function(item, position) {
    window.gtag("event", "view_promotion", {
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          creative_name: item.name,
          creative_slot: item.type,
          promotion_id: item.id,
          promotion_name: item.name,
        },
      ],
    });
  }),
  addPromotionImpressionClick: Catch(function(item, position) {
    window.gtag("event", "select_promotion", {
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          creative_name: item.name,
          creative_slot: item.type,
          promotion_id: item.id,
          promotion_name: item.name,
        },
      ],
    });
  }),
};

export default GA;
