/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";
import { AppRoot, PageContainer } from "./src/PageContainer";

export const wrapPageElement = ({ element, props }) => {
  return <PageContainer {...props}>{element}</PageContainer>;
};

export const wrapRootElement = ({ element }) => {
  return <AppRoot>{element}</AppRoot>;
};

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};
