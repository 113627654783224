import React from "react";

import GA from "../Utils/GA";
import Pixel from "../Utils/fbPixel";
import { errorHandler } from "../errors";

const ApiProxyFactory = require("../ApiProxy");
const constants = require("../constants");

class ErrCartNotAvailable extends Error {}

const Context = React.createContext();
const Actions = {};

const apiProxy = new ApiProxyFactory({ apiUrl: constants.apiUrl });

function transToQueryStr(params) {
  let query = "";
  if (typeof params === "object") {
    query = Object.keys(params)
      .filter(key => params[key] || params[key] === 0) // has value
      .reduce((e, key, idx) => {
        e = e + `${idx === 0 ? "?" : "&"}${key}=${params[key]}`;
        return e;
      }, "");
  }
  return query;
}

class Provider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };

    Actions.setToken = token => {
      apiProxy.setToken(token);
    };

    Actions.addItem = async (item, version) => {
      if (!this.state.data) {
        throw new ErrCartNotAvailable();
      }

      const event_id =
        typeof crypto?.randomUUID === "function"
          ? crypto.randomUUID()
          : new Date().toISOString();

      try {
        let resp = await apiProxy.post({
          path: "/api/cart2/add_item/",
          data: {
            ...item,
            version,
            event_id,
          },
        });
        this.setState({ data: resp.cart });

        Pixel.addToCart(
          [{ id: item.name, quantity: item.quantity }],
          item.price,
          event_id
        );
        GA.addToCart(
          {
            ...item,
            id: item.name,
          },
          item.quantity
        );
      } catch (ex) {
        console.warn("DBG", ex);
        throw ex;
      }
    };

    Actions.removeItem = async (index, item) => {
      let resp = await apiProxy.post({
        path: "/api/cart2/delete_item/",
        data: { index },
      });
      this.setState({ data: resp.cart });
      if (item) {
        try {
          GA.removeFromCart(
            {
              ...item,
              id: item.name,
            },
            item.quantity
          );
        } catch (e) {}
      }
    };

    Actions.updateItem = async (index, item, version) => {
      let resp = await apiProxy.post({
        path: "/api/cart2/edit_item/",
        data: { index, config: item.config, version },
      });
      this.setState({ data: resp.cart });
    };

    Actions.editConfig = async config => {
      try {
        let resp = await apiProxy.post({
          path: "/api/cart/edit_config/",
          data: {
            config: JSON.stringify(config),
          },
        });
        this.setState({ data: resp.cart });
        return resp.cart;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.checkout = async data => {
      try {
        let resp = await apiProxy.post({
          path: "/checkout/order/",
          data,
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.createCreditsOrder = async credits => {
      try {
        let resp = await apiProxy.post({
          path: "/api/buy_credits/",
          data: {
            credits,
          },
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.fetchCart = async () => {
      try {
        let resp = await apiProxy.get({
          path: "/api/cart/",
        });
        this.setState({ data: resp.cart });
        return resp.cart;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.setCart = async data => {
      //  for calc order response
      this.setState({ data });
    };

    Actions.clearCart = async () => {
      try {
        let resp = await apiProxy.post({
          path: "/api/cart/clear/",
        });
        this.setState({ data: resp.cart });
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.fetchOrder = async id => {
      try {
        let resp = await apiProxy.get({
          path: `/checkout/order/${id}/`,
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.fetchOrders = async (params = {}) => {
      params.ordering = "-created";
      let query = transToQueryStr(params);
      try {
        let resp = await apiProxy.get({
          path: `/checkout/order/${query}`,
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.getItemsByOrderId = async id => {
      try {
        let resp = await apiProxy.get({
          path: `/api/order_item/?order=${id}`,
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.getInvoicesByOrderId = async id => {
      try {
        let resp = await apiProxy.get({
          path: `/api/issue/invoice/?order=${id}`,
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.markOrderHasDownloadQuotationForm = async (id, boolean) => {
      try {
        let resp = await apiProxy.put({
          path: `/api/order/downloaded/${id}/`,
          data: {
            downloaded: boolean,
          },
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.getLogisticsByOrderId = async id => {
      try {
        let resp = await apiProxy.get({
          path: `/api/logistics/?order=${id}`,
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.getRefundsByOrderId = async id => {
      try {
        let resp = await apiProxy.get({
          path: `/api/refund/?order=${id}`,
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.getReturnAppsByOrderId = async id => {
      try {
        let resp = await apiProxy.get({
          path: `/api/return_app/?order=${id}`,
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.createRefund = async params => {
      try {
        let resp = await apiProxy.post({
          path: `/api/refund/`,
          data: {
            ...params,
          },
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.editRefund = async params => {
      try {
        let resp = await apiProxy.put({
          path: `/api/refund/${params.id}/`,
          data: {
            ...params,
          },
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
        throw ex;
      }
    };

    Actions.createReturnApp = async params => {
      try {
        let formData = new FormData();
        for (let key in params) {
          if (params[key] !== undefined && params[key] !== null) {
            formData.append(key, params[key]);
          }
        }
        let resp = await apiProxy.formPost({
          path: `/api/return_app/`,
          formData,
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
        throw ex;
      }
    };

    Actions.addAttatchment = async ({
      order,
      item_index,
      file_upload,
      new_file_name,
      url,
    }) => {
      try {
        let resp = await apiProxy.post({
          path: `/api/order_item/attachment/`,
          data: {
            order,
            item_index,
            url,
          },
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
        throw ex;
      }
    };

    Actions.createAttachmentInstence = async ({
      order,
      item_index,
      file_upload,
      new_file_name,
    }) => {
      try {
        let formData = new FormData();
        formData.append("order", order);
        formData.append("item_index", item_index);
        formData.append("file_upload", file_upload, new_file_name);
        let resp = await apiProxy.formPost({
          path: `/api/order_item/attachment/`,
          formData,
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
        throw ex;
      }
    };

    Actions.editOrderItem = async (id, data) => {
      let resp = await apiProxy.put({
        path: `/api/order_item/${id}/`,
        data: data,
      });
      return resp;
    };

    //deprecated
    Actions.editAttachmentNote = async ({ id, note }) => {
      try {
        let resp = await apiProxy.put({
          path: `/api/order_item/attachment/${id}`,
          data: {
            note,
          },
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.editProductionNote = async ({ id, note }) => {
      try {
        let resp = await apiProxy.put({
          path: `/api/order_item/attachment/${id}`,
          data: {
            note,
          },
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.getCurrentFeedbackPromotionByUserType = async params => {
      const query = transToQueryStr(params);
      return apiProxy.get({
        path: `/api/promotion/feedback/${query}`,
      });
    };
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

class Consumer extends React.Component {
  render() {
    return (
      <Context.Consumer>{state => this.props.children(state)}</Context.Consumer>
    );
  }
}

function withConsumer(Comp) {
  class WrappedComp extends React.Component {
    render() {
      return (
        <Consumer>{state => <Comp cart={state} {...this.props} />}</Consumer>
      );
    }
  }

  WrappedComp.displayName = `WithCart-${Comp.displayName}`;
  return WrappedComp;
}

export { Provider, Consumer, withConsumer, Actions };
