import React from "react";
import styled from "styled-components";
import * as Theme from "../Theme";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  ${props => props.css || ""}
`;

const RowCenter = styled(Row)`
  justify-content: center;
  align-items: center;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  ${props => props.css || ""}
`;

const ColCenter = styled(Col)`
  justify-content: center;
  align-items: center;
`;

const Line = styled.div`
  height: ${props => props.px || 1}px;
  ${props => props.css || ""}
`;

const LineLight = styled.div`
  height: ${props => props.px || 1}px;
  background-color: ${Theme.lineColorLight};
  ${props => props.css || ""}
`;

const LineDark = styled.div`
  height: ${props => props.px || 1}px;
  background-color: ${Theme.lineColorDark};
  ${props => props.css || ""}
`;

const ToggleDrawer = styled.div`
  display: none;
  z-index: 10;

  @media only screen and (max-width: 992px) {
    display: block;
  }

  @media only screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    right: 14px;
    bottom: 77px;
    width: 52px;
    height: 52px;
    border-radius: 25px;
    cursor: pointer;
    background-color: ${Theme.colors.main};
    box-shadow: 0px 2px 6px -3px #000;
  }
`;

const BadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid 1px #e6e6e6;
`;
const BadgeText = styled.p`
  margin: 0;
  color: #3c3c3c;
  font-size: 14px;
`;

const Badge = props => {
  let { count = 0, showZero = true } = props;
  if (count === 0 && !showZero) {
    return null;
  }

  return (
    <BadgeWrapper>
      <BadgeText>{count}</BadgeText>
    </BadgeWrapper>
  );
};

export {
  Row,
  Col,
  RowCenter,
  ColCenter,
  Line,
  LineDark,
  LineLight,
  ToggleDrawer,
  Badge,
};
